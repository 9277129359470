import React, { useEffect } from "react";
import s from './CardRatioConsumption.module.scss'
import Icon from "../../../Icon/Icon";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeActiveRoute } from "../../../../features/navigation/navigationSlice";
import { saveNavigationState } from "../../../../features/navigation/navigationActions";
import store from '../../../../redux/store';
import Loader from "../../../Loader/Loader";
import { selectAggregateData, selectDataIsLoading, selectRatioConsumption } from "../../../../selectors/selectSGE";
import { selectIndicatorType, selectIsLoading, selectError } from "../../../../selectors/selectIndicator";
import { selectActiveBuilding, selectActiveConsoUnit, selectActivePeriodUnit, selectPeriod } from "../../../../selectors/selectNavigation";
import { selectSgeAuthorization } from "../../../../selectors/selectBuildings";
import { getPeakOffpeakRatio } from '../../../../features/indicators/indicatorsActions'
import { Pie } from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import moment from 'moment'
moment.locale('fr')

ChartJS.register(ArcElement, Tooltip, Legend);

function CardRatioConsumption() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  
  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id || null
  const point_id = building?.sge_authorizations?.[0]?.point_id
  const counter = useSelector(selectSgeAuthorization(building_id, point_id), shallowEqual)
  const sge_authorization_id = counter?.sge_authorization_id
  const contract_type = counter?.technical_and_contract?.contract_type
  const periodUnit = useSelector(selectActivePeriodUnit)
  const period = useSelector(selectPeriod, shallowEqual);
  const consoUnit = useSelector(selectActiveConsoUnit)

  const indicatorData = useSelector((state) => selectIndicatorType(state, "peak_offpeak_ratio", period));
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);

  const handleNavigateTo = () => {
    dispatch(changeActiveRoute('/consult/consumption'))
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
    navigate(`/consult/consumption`)
  }

  useEffect(() => {
    const today = moment().startOf('day');
    const periodStart = moment(period.start, "YYYY-MM-DD HH:mm:ss");
    if (periodStart.isBefore(today)) {
      dispatch(getPeakOffpeakRatio({parameters: { sge_authorization_id, point_id, periodUnit, period }}));
    }
  }, [dispatch, sge_authorization_id, point_id, periodUnit, period]);

  const data = {
    labels: ["HP", "HC"],
    datasets: [
      {
        label: "Consommations",
        data: [
          consoUnit === 'kWh' ? indicatorData?.ratio?.hp : indicatorData?.ratio?.hp,
          consoUnit === 'kWh' ? indicatorData?.ratio?.hc : indicatorData?.ratio?.hc,
        ],
        backgroundColor: [
          "#2477ff",
          "#02ae51",
        ],
        borderColor: [
          "#2477ff",
          "#02ae51",
        ],
        borderWidth: 1
      }
    ]
  };

  // Options de configuration du graphique
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top"
      },
      tooltip: {
        enabled: true
      }
    }
  };

  return (
    <div className={s.root}>
      
      <header className={s.header}>
        <div className={s.title}>
          <Icon name="bolt" size={'1.5rem'} color={'#30465E'} fill={1}/> 
          <span>Rapport HP/HC</span>
        </div>
        <Icon name="arrow_forward" size={'1.5rem'} color={'#30465E'} fill={1} onClick={() => handleNavigateTo()} cursor/> 
      </header>

      {(isLoading || !indicatorData) && <Loader/>}
      
      {!isLoading && indicatorData &&
      <div className={s.content}>
        <div className={s.chart}>
          <Pie data={data} options={options}/>
        </div>
      </div>}
      
      {!isLoading && indicatorData &&
      <footer className={s.footer}>
        Consommation électrique
      </footer>}
    </div>
  );
    
}

export default CardRatioConsumption;